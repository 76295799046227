/* eslint-disable react/jsx-no-bind */
import { useUrlParams } from "findem-helpers";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { ActionWrapper, Container, DayTitle, DayWrapper, DivInputWrapper, GreySpan, H1, H1Info, HeaderContainer, PageWrapper, StyledIcon, StyledInputNumber, SuccessIcon, TimeRangeWrapper, TimeZoneContainer, Title } from "./InterviewerConfig.styled";
import { Button, Input, Select, TimePicker, theme } from 'maui';
import moment from "moment";
import timezones from 'timezones-list';
import { IAvailabilitySlots } from "../../types/calendar";
import { useCreateOrUpdatePubInterviewerMutation, useGetPubInterviewerQuery } from "../../services/pub-interviews";
import { DefaultTheme } from "styled-components";
import { useSelector } from "react-redux";
import { CustomSwitch, PrimaryButton } from "../Css/common.styled";

const DefaultDayTimes: IAvailabilitySlots = {start: 9*60, end:16*60}; // 9am to 4pm
const WeekAvailability: Record<number, IAvailabilitySlots[]> = {
  1:[DefaultDayTimes],
  2:[DefaultDayTimes],
  3:[DefaultDayTimes],
  4:[DefaultDayTimes],
  5:[DefaultDayTimes],
}
const DayMap: Record<number,string> = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
}
const DOMAIN = 'calendar.findem.ai';
const TIME_SPLITTER = '$$';
const CandidateInterviewPage = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const {accountId, token} = useUrlParams();
  const [weekAvailability, setWeekAvailability] = useState(WeekAvailability);
  const [timezone, setTimezone] = useState('America/Los_Angeles');
  const [offSet, setOffSet] = useState('-08:00'); // ['+05:30', '-07:00'
  const [defaultDuration, setDefaultDuration] = useState(30); // in minutes
  const [personalEventLink, setPersonalEventLink] = useState('intro-chat');
  const [saved, setSaved] = useState(false);

  const brandTheme: DefaultTheme = useSelector((state: any) => state.appState.theme);

  const [createOrUpdatePubInterviewer, {isLoading: processing}] = useCreateOrUpdatePubInterviewerMutation();

  // apis
  const { data: interviewerData, isLoading: interviewerLoading } = useGetPubInterviewerQuery({
    accountId: accountId!,
    id: id!,
    joid: undefined,
    token: token!,
  },{
    skip: !id || !accountId || !token || id.length < 1 || token.length < 1,
  });

  // memo
  const themeC: DefaultTheme = React.useMemo(() => {
   return brandTheme || theme;
  }, [brandTheme]);
  const interviewerHypenName = React.useMemo(() => interviewerData?.name?.replace(/\s/g, '-').toLowerCase(), [interviewerData?.name]);
  const tzOptions = React.useMemo(() => timezones.map((tz) => ({
        label: tz.name,
        value: `${tz.tzCode}${TIME_SPLITTER}${tz.utc}`,
      })), []);

  // useCallback
  const onDayAvailabilityChange = React.useCallback((day: string, checked: boolean) => {
    if (!checked) {
      const _weekAvailability = JSON.parse(JSON.stringify(weekAvailability));
      delete _weekAvailability[day];
      setWeekAvailability(_weekAvailability);
    } else {
      const _weekAvailability = JSON.parse(JSON.stringify(weekAvailability));
      _weekAvailability[day] = [DefaultDayTimes];
      setWeekAvailability(_weekAvailability);
    }
  }, [weekAvailability]);

  const addDayAvailability = React.useCallback((day: string) => {
    const _weekAvailability = JSON.parse(JSON.stringify(weekAvailability));
    _weekAvailability[day].push(DefaultDayTimes);
    setWeekAvailability(_weekAvailability);
  }, [weekAvailability]);

  const removeAvailabilityFromDay = React.useCallback((day: string, index: number) => {
    const _weekAvailability = JSON.parse(JSON.stringify(weekAvailability));
    _weekAvailability[day].splice(index, 1);
    setWeekAvailability(_weekAvailability);
  }, [weekAvailability]);

  const onTimeChangeHandler = React.useCallback((day: string, index: number, time: any, type: 'start' | 'end') => {
    const _weekAvailability = JSON.parse(JSON.stringify(weekAvailability));
    const timeInMinutes = time.hour() * 60 + time.minute();
    _weekAvailability[day][index][type] = timeInMinutes;
    setWeekAvailability(_weekAvailability);
  }, [weekAvailability]);

  const onTimezoneChange = React.useCallback((value: string) => {
    const tz = value.split(TIME_SPLITTER);
    setTimezone(tz[0]);
    setOffSet(tz[1]);
  }, []);

  const savePreferences = React.useCallback(async () => {
    const payload = {
      ...interviewerData,
      name: interviewerData?.name || 'Candidate',
      weekly_availability: {slots: weekAvailability},
      timezone,
      timezone_offset: offSet,
      default_meeting_duration: defaultDuration,
      default_slug: personalEventLink,
      token: token!,
    }
    const res = await createOrUpdatePubInterviewer(payload);
    setSaved(true);
  }
  , [interviewerData, weekAvailability, 
    timezone, defaultDuration, offSet,
    personalEventLink, createOrUpdatePubInterviewer, token
  ]);
  const closeWindow = React.useCallback(() => {
    setSaved(true);
  }, []);
  
  const setPersonalEventLinkHandler = React.useCallback((e: any) => {
    setPersonalEventLink(e.target.value);
  }, []);

  const setDefaultDurationHandler = React.useCallback((value: any) => {
    setDefaultDuration(value);
  }, []);

  // effects
  React.useEffect(() => {
    if(interviewerData?.timezone){
      setTimezone(interviewerData.timezone);
    }
    if(interviewerData?.timezone_offset){
      setOffSet(interviewerData.timezone_offset);
    }
    if(interviewerData?.weekly_availability?.slots){
      setWeekAvailability(interviewerData.weekly_availability.slots);
    }
    setDefaultDuration(interviewerData?.default_meeting_duration || 30);
    setPersonalEventLink(interviewerData?.default_slug || '');
  }, [interviewerData]);

  return <PageWrapper><Container>
    {!saved ? <><HeaderContainer>
      <H1>Set your availability</H1>
      <H1Info>Set your availability for appointments and events.</H1Info>
    </HeaderContainer>
    <TimeZoneContainer>
      <div>
        <Title style={{marginBottom: 2}}>Timezone</Title>
        <Select
          showSearch={true}
          dropdownMatchSelectWidth={false}
          options={tzOptions}
          value={timezone+TIME_SPLITTER+offSet}
          onChange={onTimezoneChange}
          filterOption={(input, option) =>  
            ((option?.label || '').toLowerCase().indexOf(input.toLowerCase()) >= 0 
            || (option?.value || '').toLowerCase().indexOf(input.toLowerCase()) >= 0)
          }
        />
      </div>
      {Object.keys(DayMap).map((day: any, index: number) => {
        const dayName = DayMap[parseInt(day)];
        const dayAvailability = weekAvailability[parseInt(day)];
        return <DayWrapper key={dayName}>
          <CustomSwitch className="switch-color" $color={themeC.colors['blue-400']} size="small" checked={dayAvailability ? true : false} onChange={(checked: boolean) => onDayAvailabilityChange(day, checked)}/>
          <DayTitle>{dayName}</DayTitle>
          <TimeRangeWrapper>
            {dayAvailability && dayAvailability.map((timeRange, index) => <div key={index}
                style={{
                  display: 'flex', justifyContent: 'space-between', alignItems: 'center'
                }}
              >
                <div>
                  <TimePicker
                    allowClear={false}
                    className="time-picker"
                    value={moment().startOf('day').add(timeRange.start, 'minutes')}
                    format="h:mm a"
                    minuteStep={30}
                    use12Hours={true}
                    suffixIcon={<></>}
                    showNow={false}
                    onChange={(time) => onTimeChangeHandler(day, index, time, 'start')}
                  />
                  {' - '}
                  <TimePicker
                    allowClear={false}
                    className="time-picker"
                    value={moment().startOf('day').add(timeRange.end, 'minutes')}
                    format="h:mm a"
                    minuteStep={30}
                    use12Hours={true}
                    suffixIcon={<></>}
                    showNow={false}
                    onChange={(time) => onTimeChangeHandler(day, index, time, 'end')}
                  />
                </div>
                {dayAvailability && <ActionWrapper style={{width: '100px', textAlign: 'end' }}>
                  <Button type="text"  disabled={dayAvailability.length >= 3} style={{padding: 0}} onClick={() => addDayAvailability(day)} ><StyledIcon icon="add_circle" $hoverColor={theme.colors['blue-400']} /></Button>
                  <Button type="text" disabled={dayAvailability.length === 1} style={{padding: 0}} onClick={() => removeAvailabilityFromDay(day, index)} ><StyledIcon icon="delete" $hoverColor={theme.colors['red-400']}/></Button>
                </ActionWrapper>}
              </div>)}
          </TimeRangeWrapper>
        </DayWrapper>
      })}
      <div>
        <Title style={{marginBottom: 2}}>Personal Event Link</Title>
        <DivInputWrapper>
          <GreySpan>{DOMAIN}/<Input placeholder={interviewerHypenName+'-'+{defaultDuration}+'mins'} value={personalEventLink} debounceDelay={0} onChange={setPersonalEventLinkHandler} /></GreySpan>
        </DivInputWrapper>
      </div>
      <div>
        <Title style={{marginBottom: 2}}>Default Duration Time <GreySpan>(in mins)</GreySpan></Title>
        <StyledInputNumber
          onChange={setDefaultDurationHandler}
          value={defaultDuration}
          min={5}
          max={120}
        />
      </div>
      <ActionWrapper>
        <PrimaryButton $color={themeC.colors['blue-400']} style={{width: '100%'}} loading={processing} onClick={savePreferences}>Finish</PrimaryButton>
        <Button type="text" style={{width: '100%'}} onClick={closeWindow}>I'll Do This Later</Button>
      </ActionWrapper>
    </TimeZoneContainer></> :
    <>
      <HeaderContainer>
        <H1><SuccessIcon $color={theme.colors['green-400']} icon="event_available"/></H1>
        <H1Info color={theme.colors['grey-700']}>Thank you! Your preferences have been saved.</H1Info>
      </HeaderContainer>
    </>}
  </Container></PageWrapper>;
};

export default CandidateInterviewPage;