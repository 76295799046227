import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';

import { apiUrl, constructUrlWithParams, interviewUrl, returnUrlParamAccountIdOrJoid } from 'findem-helpers';

import { IBookCandidateInterviewOneOffPubParams, IBookCandidateInterviewPubParams, ICandidateInterviewSlot, IEvent2, IInterviewBranding, IInterviewCandidateOneOffParams, IInterviewCandidateParams2, IInterviewCandidatePubParams1, IInterviewEvents, IInterviewer, ISpecAssignment, ITeamPubParams } from '../types/calendar';

interface IAccountJoidParams {
  accountId: string | undefined;
  joid: string | undefined;
  token: string;
}

interface IDetailsParams extends IAccountJoidParams {
  id: string;
}

interface IICPInterviewSpecParams extends IAccountJoidParams {
  icp_id: string;
}

interface IProfileParams {
  profile_ids: string[];
}

export const interviewsPubApi = createApi({
  reducerPath: 'pub-interviews',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl
  }),
  endpoints: (build) => ({
    getPubInterviewer: build.query<IInterviewer, IDetailsParams>({
      query: (params: IDetailsParams) => {
        const queryParams = returnUrlParamAccountIdOrJoid(params.accountId, params.joid);
        if(params.joid){
          queryParams.joid = params.joid;
        }
        queryParams.id = params.id;
        const url: string = constructUrlWithParams('/pub_api/v1/cal/interviewer', queryParams);
        return {
          url,
          credentials: 'include',
          method: 'GET',
          headers: {
            'Authorization': `Api-Key ${params.token}`
          }
        }
      },
      transformResponse: (response: {interviewer: IInterviewer}) => response.interviewer
    }),
    getPubInterviewPageBranding: build.query<IInterviewBranding, IAccountJoidParams>({
      query: (params: IAccountJoidParams) => {
        const queryParams = returnUrlParamAccountIdOrJoid(params.accountId, params.joid);
        if(params.joid){
          queryParams.joid = params.joid;
        }
        const url: string = constructUrlWithParams('/pub_api/v1/cal/interview_branding', queryParams);
        return {
          url,
          credentials: 'include',
          method: 'GET',
          headers: {
            'Authorization': `Api-Key ${params.token}`
          }
        }
      },
      transformResponse: (response: {branding: IInterviewBranding}) => response.branding
    }),
    getPubInterviewPageBrandingByCaptcha: build.query<IInterviewBranding, IAccountJoidParams>({
      query: (params: IAccountJoidParams) => {
        const queryParams = returnUrlParamAccountIdOrJoid(params.accountId, params.joid);
        if(params.joid){
          queryParams.joid = params.joid;
        }
        if(params.token){
          queryParams.token = params.token;
          queryParams.calendar = 'true';
        }
        const url: string = constructUrlWithParams('/pub_cal_api/v1/cal/interview_branding', queryParams);
        return {
          url,
          credentials: 'include',
          method: 'GET',
        }
      },
      transformResponse: (response: {branding: IInterviewBranding}) => response.branding
    }),
    getPubInterviewPageLogo: build.query<string, IAccountJoidParams>({
      query: (params: IAccountJoidParams) => {
        const queryParams = returnUrlParamAccountIdOrJoid(params.accountId, params.joid);
        if(params.joid){
          queryParams.joid = params.joid;
        }
        const url: string = constructUrlWithParams('/pub_api/v1/cal/interview_branding/logo', queryParams);
        return {
          url,
          credentials: 'include',
          method: 'GET',
          headers: {
            'Authorization': `Api-Key ${params.token}`
          }
        }
      },
      transformResponse: (response: {logo: string}) => response.logo
    }),
    getPubInterviewPageLogoByToken: build.query<string, IAccountJoidParams>({
      query: (params: IAccountJoidParams) => {
        const queryParams = returnUrlParamAccountIdOrJoid(params.accountId, params.joid);
        if(params.token){
          queryParams.token = params.token;
          queryParams.calendar = 'true';
        }
        const url: string = constructUrlWithParams('/pub_cal_api/v1/cal/interview_branding/logo', queryParams);
        return {
          url,
          credentials: 'include',
          method: 'GET',
        }
      },
      transformResponse: (response: {logo: string}) => response.logo
    }),
    getPubInterviewerSlugDetails: build.query<IInterviewer, {slug: string, token: string}>({
      query: (params: {slug: string, token: string}) => {
        return {
          url: `/pub_cal_api/v1/cal/interviewer?slug=${params.slug}&token=${params.token}&calendar=true`,
          credentials: 'include',
          method: 'GET'
        }
      },
      transformResponse: (response: {interviewer: IInterviewer}) => response.interviewer
    }),
    createOrUpdatePubInterviewer: build.mutation<IInterviewer, ITeamPubParams>({
      query: (team: ITeamPubParams) => ({
        url: `/pub_api/v1/cal/interviewer`,
        credentials: 'include',
        method: 'PUT',
        body: {...team, preferences: { max_interview_per_week: 30 }},
        headers: {
          'Authorization': `Api-Key ${team.token}`
        }
      }),
      transformResponse: (response: IInterviewer) => response
    }),
    getPubCandidateInterviewC: build.query<ICandidateInterviewSlot[][], IInterviewCandidatePubParams1>({
      query: (params: IInterviewCandidatePubParams1) => {
        const queryParams = {
          interview_candidate_id: params.interview_candidate_id,
          ...returnUrlParamAccountIdOrJoid(params.accountId, undefined)
        };
        const url: string = constructUrlWithParams('/pub_api/v1/cal/interview_candidate/available_slots', queryParams);
        return {
          url,
          credentials: 'include',
          method: 'GET',
          headers: {
            'Authorization': `Api-Key ${params.token}`
          }
        }
      },
      transformResponse: (response: ICandidateInterviewSlot[][]) => response
    }),
    getPubIcpInterviewSpecsC: build.query<ISpecAssignment, IICPInterviewSpecParams>({
      query: (params: IICPInterviewSpecParams) => {
        const queryParams = returnUrlParamAccountIdOrJoid(params.accountId, params.joid);
        if(params.joid){
          queryParams.joid = params.joid;
        }
        queryParams.icp_id = params.icp_id;

        const url: string = constructUrlWithParams('/pub_api/v1/cal/spec/search', queryParams);
        return {
          url,
          credentials: 'include',
          method: 'GET',
          headers: {
            'Authorization': `Api-Key ${params.token}`,
          }
        }
      },
      transformResponse: (response: {spec_assignment: ISpecAssignment}) => response.spec_assignment
    }),
    bookPubCandidateInterviewC: build.mutation<IInterviewEvents, IBookCandidateInterviewPubParams>({
      query: (params: IBookCandidateInterviewPubParams) => ({
        url: `/pub_api/v1/cal/interview_candidate/book_slot`,
        credentials: 'include',
        method: 'POST',
        body: params,
        headers: {
          'Authorization': `Api-Key ${params.token}`,
        }
      }),
      transformResponse: (response: {events: IInterviewEvents}) => response.events
    }),
    bookPubCandidateInterviewOneOffC: build.mutation<IInterviewEvents, IBookCandidateInterviewOneOffPubParams>({
      query: (params: IBookCandidateInterviewOneOffPubParams) => ({
        url: `/pub_cal_api/v1/cal/interview_candidate/book_oneoff_slot?account_id=${params.account_id}&calendar=${params.calendar}`,
        credentials: 'include',
        method: 'POST',
        body: {...params, calendar: true}
      }),
      transformResponse: (response: {events: IInterviewEvents}) => response.events
    }),
    candidatePubBookingsC: build.query<IEvent2[], IInterviewCandidateParams2>({
      query: (params: IInterviewCandidateParams2) => {
        const queryParams = {
          interview_candidate_id: params.interview_candidate_id,
        };
        const url: string = constructUrlWithParams('/pub_api/v1/cal/interview_candidate/bookings', queryParams);
        return {
          url,
          credentials: 'include',
          method: 'GET',
          headers: {
            'Authorization': `Api-Key ${params.token}`,
          }
        }
      },
      transformResponse: (response: {bookings: IEvent2[]}) => response.bookings
    }),
    // getCandidatePubInterviewOneOffC: build.query<ICandidateInterviewSlot[][], IInterviewCandidateOneOffParams>({
    //   query: (params: IInterviewCandidateOneOffParams) => {
    //     const queryParams = {
    //       slug: params.slug
    //     };
    //     const url: string = constructUrlWithParams(`${interviewUrl}/interview/api/interview_candidate/oneoff/available_slots`, queryParams);
    //     return {
    //       url,
    //       credentials: 'include',
    //       method: 'GET',
    //       headers: {
    //         'Authorization': `Api-Key ${params.token}`,
    //       }
    //     }
    //   },
    //   transformResponse: (response: ICandidateInterviewSlot[][]) => response
    // }),
    getCandidatePubInterviewOneOffC: build.query<ICandidateInterviewSlot[][], IInterviewCandidateOneOffParams>({
      query: (params: IInterviewCandidateOneOffParams) => {
        const queryParams = {
          slug: params.slug,
          token: params.token || '',
          calendar: true.toString()
        };
        const url: string = constructUrlWithParams('/pub_cal_api/v1/cal/interview_candidate/oneoff/available_slots', queryParams);
        return {
          url,
          credentials: 'include',
          method: 'GET',
        }
      },
      transformResponse: (response: ICandidateInterviewSlot[][]) => response
    }),
  })
});

export const {
  endpoints,
  useGetPubInterviewerQuery,
  useGetPubInterviewPageBrandingQuery,
  useGetPubInterviewPageBrandingByCaptchaQuery,
  useGetPubInterviewPageLogoQuery,
  useGetPubInterviewerSlugDetailsQuery,
  useCreateOrUpdatePubInterviewerMutation,
  useGetPubCandidateInterviewCQuery,
  useGetPubIcpInterviewSpecsCQuery,
  useBookPubCandidateInterviewCMutation,
  useBookPubCandidateInterviewOneOffCMutation,
  useCandidatePubBookingsCQuery,
  useGetCandidatePubInterviewOneOffCQuery,
  useGetPubInterviewPageLogoByTokenQuery
} = interviewsPubApi;
