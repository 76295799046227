import { Input, Text, TextProps } from "maui";
import styled, { css } from "styled-components";

export const CandidateInterviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: ${({ theme: { colors } }) => colors['grey-100']};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 16px;
  background: #FFF;
  box-shadow: -2px 2px 8px 0px rgba(34, 35, 61, 0.08);
  min-width: 800px;
`;

// Info component styles

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const NameWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const HI = styled.div`
  color: ${({ theme: { colors } }) => colors['grey-700']};
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
`;
export const HiInfo = styled.div`
  color: ${({ theme: { colors } }) => colors['grey-500']};
  text-align: center;
  /* Text/Default */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;
export const DetailsWrapper = styled.div`
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  gap: 12px;
  margin-top: 32px;
  border-radius: 12px;
  background: ${({ theme: { colors } }) => colors['grey-100']};
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Schedule component styles
export const ScheduleWrapper = styled.div`
  display: flex;
  flex-direction: Row;
  justify-content: center;
  width: 800px;
`;

export const ScheduleItemWrapper = styled.div<{
  showBackground?: boolean;
}>`
  flex: 1;
  padding: 16px;
  border-radius: 12px;
  background: ${({ showBackground, theme: { colors } }) =>
    showBackground ? colors['grey-100'] : colors['white']};
`;
export const DateCellWrapper = styled.div<{
  bgColor?: string;
  color?: string;
}>`
  display: inline-block;
  padding: 4px;
  border-radius: 50%;
  background-color: ${({ bgColor, theme }) => bgColor ?? theme.colors['white']};
  color: ${({ color, theme }) => color ?? theme.colors['grey-700']};
  width: 28px;
`;
export const TimeSlotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding-top: 10px;
  gap: 8px;
  margin-top: 16px;
`;
export const Slots = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  max-height: 260px;
  overflow: auto;
  padding-right: 16px;
`;
export const DayTitle = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
`;
export const BtnTxt = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const BtnTimeSlot = styled.div`
  display inline-block;
`;
export const BtnSlotInfo = styled.div<{
  $isActive?: boolean;
}>`
  display inline-block;
  padding: 0px 4px;
  background: ${({ $isActive, theme: { colors } }) => $isActive ? colors['white'] : colors['grey-100']};
  color: ${({ theme: { colors } }) => colors['grey-600']};
  border-radius: 100px;
`;
// confirmation form styles
export const ItemWrapper = styled.div<{
  $isLast?: boolean;
}>`
  padding-bottom: ${({ theme: { dimensions } }) => dimensions.spacing[1]};

  ${({ $isLast }) => $isLast && css`
    padding-bottom: 0;
  `}

  .ant-form-item {
    margin-bottom: 0;
  }
`;
export const ItemLabel = styled(Text)<TextProps>`
  font-weight: 400;
  padding-bottom: 4px;
`;
export const StyledInput = styled(Input)`
  display: flex;
  flex: 1 1 auto;
  margin-bottom: ${({ theme: { dimensions } }) => dimensions.spacing[1]};

  .ant-select-selector {
    display: flex;
    flex: 1 1 auto;
  }
`;
export const BackWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
  color: ${({ theme: { colors } }) => colors['grey-500']};
`;