/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { BtnSlotInfo, BtnTimeSlot, BtnTxt, DateCellWrapper, DayTitle, ScheduleItemWrapper, ScheduleWrapper, Slots, TimeSlotWrapper } from './CandidateInterview.styled';
import { Button, Calendar, Col, Icon, Row } from 'maui';
import moment, { Moment } from 'moment';
import { IInterviewCandidateSlots, ISlot } from '../../types/calendar';
import { appendPlural } from 'findem-helpers';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';

interface IScheduleProps {
  selectSlot: (slot: ISlot) => void;
  slotsByDay: IInterviewCandidateSlots | undefined;
  selectedSlot: ISlot | undefined;
}

const Schedule: React.FC<IScheduleProps> = ({ selectSlot, slotsByDay, selectedSlot }) => {
  const [currentDate, setCurrentDate] = React.useState<Moment>(moment());
  const themeC: DefaultTheme = useSelector((state: any) => state.appState.theme);
  const onChange = (date: Moment) => {
    setCurrentDate(date);
  };
  const onPanelChange = (date: Moment) => {
    setCurrentDate(date);
  };

  const selectedDaySlots = React.useMemo(() => {
    const slots: ISlot[] = [];
    if (slotsByDay && slotsByDay[currentDate.format('YYYY-MM-DD')]) {
      slotsByDay[currentDate.format('YYYY-MM-DD')].forEach((slot) => {
        slots.push(slot);
      });
    }
    return slots;
  }, [currentDate, slotsByDay]);

  const dateCellRender = React.useCallback((value: Moment) => {
    const listData = slotsByDay && slotsByDay[value.format('YYYY-MM-DD')] ? slotsByDay[value.format('YYYY-MM-DD')] : [];
    const selMonth = currentDate.month();
    const valMonth = value.month();
    const currDate = currentDate.date();
    const valDate = value.date();
    return (
      <>
        {currDate === valDate && selMonth === valMonth && (<DateCellWrapper color={themeC.colors['white']} bgColor={themeC.colors['blue-400']}>
          {value.date()}
          </DateCellWrapper>)}
        {currDate !== valDate && listData.length > 0 && (<DateCellWrapper bgColor={themeC.colors['blue-200']}>{value.date()}</DateCellWrapper>)}
        {currDate !== valDate && selMonth === valMonth && listData.length === 0 && (<DateCellWrapper bgColor={themeC.colors['white']}>{value.date()}</DateCellWrapper>)}
        {currDate !== valDate && selMonth !== valMonth && listData.length === 0 && (<DateCellWrapper color={themeC.colors['grey-500']} bgColor={themeC.colors['white']}>{value.date()}</DateCellWrapper>)}
        {currDate === valDate && selMonth !== valMonth && listData.length === 0 && (<DateCellWrapper color={themeC.colors['grey-500']} bgColor={themeC.colors['white']}>{value.date()}</DateCellWrapper>)}
      </>
    );
  },[slotsByDay, currentDate]);

  React.useEffect(() => {
    if(selectedSlot && selectedSlot.slot[0]){
      setCurrentDate(moment(selectedSlot.slot[0].start));
    }
  }, [selectedSlot]);
  return (
    <ScheduleWrapper>
      <ScheduleItemWrapper>
        <Calendar
          fullscreen={false}
          value={currentDate}
          onPanelChange={onPanelChange}
          onChange={onChange}
          disabledDate={(current) => moment().add(-1, 'days')  >= current ||
                 moment().add(1, 'month')  <= current}
          headerRender={({ value, type, onChange, onTypeChange }) => {
            const _value = value.clone();
            const nextMonth = () => {
              // if differance is greater than 1 month then show error
              _value.add(1, 'month');
              if (_value.diff(moment(), 'day') > 30) {
                onChange(currentDate);
              } else{
                onChange(_value);
              }
            }
            const previousMonth = () => {
              // don't go beyond current month
              _value.subtract(1, 'month');
              if (_value.diff(moment(), 'day') < 0) {
                onChange(moment());
              } else{
                onChange(_value);
              }
            }
            return (
              <div style={{ padding: 10 }}>
                <div style={{ marginBottom: "10px" }} />
                <Row style={{display: 'flex', justifyContent: 'center'}}>
                  <Col span={4}>
                    <Button type="text" onClick={previousMonth}><Icon icon="chevron_left" $size={16}/></Button>
                  </Col>
                  <Col span={16} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {/* <FaChevronLeft /> */}
                    {value.format('MMMM YYYY')}
                    <p />
                  </Col>
                  <Col span={4} style={{textAlign: 'end'}}>
                    <Button type="text" onClick={nextMonth}><Icon icon="chevron_right" $size={16}/></Button>
                  </Col>
                </Row>
              </div>
            );
          }}
          dateFullCellRender={dateCellRender}
        />
      </ScheduleItemWrapper>
      <ScheduleItemWrapper showBackground={true}>
        <TimeSlotWrapper>
          <DayTitle>{currentDate.format('dddd, MMMM Do, YYYY')}</DayTitle>
          <Slots>
            {selectedDaySlots.length > 0 && selectedDaySlots.map((slot, index) => (
              <Button
                key={index}
                type={ selectedSlot && selectedSlot.slot[0] === slot.slot[0] && selectedSlot.slot[1] === slot.slot[1] ? 'primary' : 'default'}
                style={{
                  color: selectedSlot && selectedSlot.slot[0] === slot.slot[0] && selectedSlot.slot[1] === slot.slot[1] ? themeC.colors['white'] : themeC.colors['grey-700'],
                  backgroundColor: selectedSlot && selectedSlot.slot[0] === slot.slot[0] && selectedSlot.slot[1] === slot.slot[1] ? themeC.colors['blue-400'] : themeC.colors['white'],
                  borderColor: selectedSlot && selectedSlot.slot[0] === slot.slot[0] && selectedSlot.slot[1] === slot.slot[1] ? themeC.colors['blue-400'] : themeC.colors['grey-400'],
                  marginBottom: 8
                }}
                onClick={() => selectSlot(slot)}
              >
                <BtnTxt>
                  <BtnTimeSlot>{slot.label || `${moment(slot.slot[0].start).format('h:mm A')} - ${moment(slot.slot.at(-1)?.end).format('h:mm A')}`}</BtnTimeSlot>
                  <BtnSlotInfo $isActive={selectedSlot && selectedSlot.slot[0] === slot.slot[0] && selectedSlot.slot[1] === slot.slot[1]}>
                    <Icon icon="schedule" style={{position: 'relative', top: 2, marginRight: 2}}/>
                    {slot.duration && slot.duration > 0 && `${slot.duration} mins `}
                    {slot.slot.length} event{appendPlural(slot.slot.length)}
                  </BtnSlotInfo>
                </BtnTxt>
                
              </Button>
            ))}
            {selectedDaySlots.length === 0 && <div>No slots available</div>}
          </Slots>
        </TimeSlotWrapper>
      </ScheduleItemWrapper>
    </ScheduleWrapper>
  );
};

export default Schedule;