import { apiUrl, useUrlParams } from "findem-helpers";
import React from "react";
import { useParams } from "react-router-dom";
import { CandidateInterviewWrapper, ContentWrapper, HI, HiInfo, InfoWrapper } from "./CandidateInterview.styled";
import InterviewInfo from "./InterviewInfo";
import { STEP } from "./constant";
import Schedule from "./Schedule";
import { Skeleton } from "maui";
import { durationInMinutes, slotsByDayHelper } from "./helper";
import ConfirmationForm from "./ConfirmationForm";
import { useGetProfilesQuery } from "../../services/interviews";
import moment from "moment";
import { ICandidateInterviewSlot, IInterviewBranding, IInterviewCandidate, IInterviewCandidateSlots, IInterviewSpec, ISlot, ISpecAssignment } from "../../types/calendar";
import { useCandidatePubBookingsCQuery, useGetPubCandidateInterviewCQuery, useGetPubIcpInterviewSpecsCQuery, useGetPubInterviewPageLogoQuery } from "../../services/pub-interviews";
import { useSelector } from "react-redux";

const CandidateInterview = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const {accountId, token} = useUrlParams();
  
  // states
  const [interviewSlots, setInterviewSlots] = React.useState<ICandidateInterviewSlot[][] | undefined>(undefined);
  const [slotsByDay, setSlotsByDay] = React.useState<IInterviewCandidateSlots | undefined>(undefined);
  const [specAssignment, setSpecAssignment] = React.useState<ISpecAssignment | undefined>(undefined);
  const [interviewCandidate, setInterviewCandidate] = React.useState<IInterviewCandidate | undefined>(undefined);
  const [specDetails, setSpecDetails] = React.useState<IInterviewSpec | undefined>(undefined);
  const [step, setStep] = React.useState<STEP>(STEP.INTRO);
  const [slotObj, setSlotObj] = React.useState<ISlot | undefined>(undefined); // [start_ts, end_ts]
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

    // context state
    const branding: IInterviewBranding | undefined = useSelector((state: any) => state.appState.branding);

  // apis
  const { data: savedLogo, 
    isLoading: isLogoLoading 
  } = useGetPubInterviewPageLogoQuery({
      accountId: accountId, 
      joid: undefined, token: token || '' 
    }, {skip: !accountId || !token || token.length < 1});
  // check for existing booking
  const {data: candidateEvent, isLoading: isCandidateEventsLoading} = useCandidatePubBookingsCQuery({
    interview_candidate_id: id || '',
    token: token || '',
  },{
    skip: !id || !token || token.length < 1,
  });
  // check for slots
  const {data: candidateInterviewSlots, refetch: refetchSlots} = useGetPubCandidateInterviewCQuery({
    interview_candidate_id: id || '',
    accountId: accountId || '',
    token: token || '',
  },{
    skip: !id || !accountId || !token || id.length < 1 || token.length < 1,
  });
  // need to move below api to public
  // api to get spec details
  const {
    data: icpInterviewSpec,
  } = useGetPubIcpInterviewSpecsCQuery(
    {
      icp_id: interviewCandidate?.profile_container_cxt?.icp_id || '',
      accountId: accountId || '',
      joid: undefined,
      token: token || '',
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !interviewCandidate?.profile_container_cxt?.icp_id || !accountId || !token || token.length < 1,
    }
  );
  
  // api to get profile details
  const { data: profiles } = useGetProfilesQuery(
    {
      profile_ids: [interviewCandidate ? interviewCandidate.prid : ''],
    },
    {
      skip: !interviewCandidate?.prid
    }
  );

  // Memo
  const candidateName: string = React.useMemo(() => {
    if (interviewCandidate && profiles) {
      return `${profiles[interviewCandidate.prid].candidate.full_name}`;
    }
    return '';
  }, [interviewCandidate, profiles]);

  const loading: boolean = React.useMemo(() => {
    return isLoading || isLogoLoading || !specDetails || isCandidateEventsLoading;
  }, [isCandidateEventsLoading, isLoading, isLogoLoading, specDetails]);

  const step2 = React.useCallback(() => {
    setStep(STEP.CALENDAR);
  },[]);

  const step3 = React.useCallback(() => {
    setStep(STEP.FORM);
  },[]);

  const selectSlot = React.useCallback((slot: ISlot) => {
    setSlotObj(slot);
    step3();
  },[step3]);

  const onSubmitCB = React.useCallback(() => {
    setStep(STEP.BOOKED);
  }, []);

  React.useEffect(() => {
    if (candidateInterviewSlots) {
      setInterviewSlots(candidateInterviewSlots);
    }
  }, [candidateInterviewSlots]);

  React.useEffect(() => {
    if (icpInterviewSpec) {
      setSpecAssignment(icpInterviewSpec);
    }
  }, [icpInterviewSpec]);

  // API flow := candidate interview > spec assignment > spec details

  React.useEffect(() => {
    if(candidateEvent && candidateEvent.length > 0){
      const slot: ICandidateInterviewSlot = {
        start: moment(candidateEvent[0].start_time).unix()*1000,
        end: moment(candidateEvent[0].end_time).unix()*1000,
        interviewer_id: candidateEvent[0].interviewer_ref,
      }
      setSlotObj({
        slot: [slot],
        duration: durationInMinutes([slot]),
        label: moment(slot.start).format('h:mm A') + ' - ' + moment(slot.end).format('h:mm A'),
      });
      setStep(STEP.BOOKED);
    }
  },[candidateEvent]);

  React.useEffect(() => {
    const getSpecDetails = async (id: string, accountId: string, token: string) => {
      fetch(`${apiUrl}/pub_api/v1/cal/interview_candidate?interview_candidate_id=${id}&account_id=${accountId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Api-Key ${token}`
        },
        credentials: 'include'
      }).then((response) => response.json()).then((data) => {
        setInterviewCandidate(data.candidate as IInterviewCandidate);
      }).catch((error) => {
        console.log('error', error);
      });
    }
    if(id && accountId && token){
      getSpecDetails(id, accountId, token);
    }
  }, []);


  React.useEffect(() => {
    const getSpecDetails = async (id: string) => {
      fetch(`${apiUrl}/pub_api/v1/cal/spec?spec_id=${id}&account_id=${accountId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Api-Key ${token}`
        },
        credentials: 'include'
      }).then((response) => response.json()).then((data) => {
        setSpecDetails(data.spec as IInterviewSpec);
        setIsLoading(false);
      }).catch((error) => {
        console.log('error', error);
      });
    }
    if(specAssignment && specAssignment?.interview_spec_id && accountId && token){
      getSpecDetails(specAssignment?.interview_spec_id);
    }
  }, [accountId, specAssignment]);

  React.useEffect(() => {
    if(interviewSlots){
      setSlotsByDay(slotsByDayHelper(interviewSlots));
    }
  }, [interviewSlots]);

  return <CandidateInterviewWrapper>
    <ContentWrapper>
      {specDetails && !loading && step === STEP.INTRO && <InterviewInfo logoUrl={savedLogo} spec={specDetails} goToSchedule={step2} candidateName={candidateName} />}
      {specDetails && !loading && step === STEP.CALENDAR && <Schedule 
          selectSlot={selectSlot} 
          slotsByDay={slotsByDay}
          selectedSlot={slotObj}
        />
      }
      {slotObj && id && !loading && accountId && step === STEP.FORM && <ConfirmationForm 
          slotObj={slotObj}
          goBack={step2}
          interview_candidate_id={id}
          onSubmitCB={onSubmitCB}
          refetchSlots={refetchSlots}
          logoUrl={savedLogo}
          accountId={accountId}
      />}
      {loading && <Skeleton title={false} loading={loading} paragraph={{rows: 4}}/>}
      {step === STEP.BOOKED && slotObj && <InfoWrapper>
        <HI>Interview Booked</HI>
        <HiInfo style={{marginTop: 8}}>
        {(!branding || !branding.thank_you_msg) && <>Your interview has been booked for {moment(slotObj.slot[0].start).format('dddd, MMMM Do')} at {moment(slotObj.slot[0].start).format('h:mm A')}.</>}
          {branding && branding.thank_you_msg && <> {branding.thank_you_msg.replace('{{date}}', `${moment(slotObj.slot[0].start).format('dddd, MMMM Do')} at ${moment(slotObj.slot[0].start).format('h:mm A')}`)}</>}
        </HiInfo>
      </InfoWrapper>}
    </ContentWrapper>
  </CandidateInterviewWrapper>;
};

export default CandidateInterview;