import React, { useRef } from 'react';
import { BackWrapper, DayTitle, HiInfo, ItemLabel, ItemWrapper, LogoWrapper, ScheduleItemWrapper, ScheduleWrapper, StyledInput, TimeSlotWrapper } from './CandidateInterview.styled';
import moment from 'moment';
import logo from '../../assets/svgs/findem_logo.svg';
import { Form, Icon } from 'maui';
import { Button } from 'antd';
import { IBookCandidateInterviewOneOffPubParams, IBookCandidateInterviewPubParams, ICandidateInterviewSlot, ISlot } from '../../types/calendar';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { useUrlParams } from 'findem-helpers';
import { useBookPubCandidateInterviewCMutation, useBookPubCandidateInterviewOneOffCMutation } from '../../services/pub-interviews';

interface IConfirmationFormProps {
  slotObj: ISlot;
  goBack: () => void;
  interview_candidate_id: string;
  onSubmitCB: () => void;
  refetchSlots: () => void;
  isPersonalCal?: boolean;
  logoUrl?: string | undefined;
  recaptcha?: any;
  accountId: string;
}

const ConfirmationForm: React.FC<IConfirmationFormProps> = ({ 
  slotObj, goBack, interview_candidate_id, 
  onSubmitCB, refetchSlots, isPersonalCal, 
  logoUrl, recaptcha, accountId
}) => {
  const {token} = useUrlParams();
  
  const [bookCandidateInterview, {isLoading: isSubmitting}] = useBookPubCandidateInterviewCMutation();
  const [bookPersonalInterview, {isLoading: isPersonalCalSubmitting}] = useBookPubCandidateInterviewOneOffCMutation();

  const themeC: DefaultTheme = useSelector((state: any) => state.appState.theme);

  const [email, setEmail] = React.useState<string | undefined>(undefined);
  const [name, setName] = React.useState<string | undefined>(undefined);
  const [showError, setShowError] = React.useState<boolean>(false);
  const [tokenParam, setTokenParam] = React.useState<string | undefined>(undefined);

  const isValidEmail: boolean = React.useMemo(() => {
    if(!email) return false;
    const emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
    return emailRegex.test(email);
  }, [email]);

  const onSubmit = React.useCallback( async () => {
    if(!email || !name || (!isPersonalCal && !accountId)) return;
    setShowError(false);
    let _onDemandToken: string | undefined = undefined;
    if(recaptcha) {
      _onDemandToken = await recaptcha?.current?.execute();
    }
    const payload: IBookCandidateInterviewPubParams | IBookCandidateInterviewOneOffPubParams = Object.assign({
      name: name,
      email_id: email,
      booking_slot_details: {},
      account_id: accountId,
      token: _onDemandToken || tokenParam || '',
      calendar: true,
    }, !isPersonalCal ? {
      interview_candidate_id: interview_candidate_id,
    } : {});
    slotObj.slot.forEach((slot: ICandidateInterviewSlot)=>{
      payload.booking_slot_details[slot.interviewer_id] = {
        start_ts: slot.start,
        end_ts: slot.end,
      };
    })
    const resp = isPersonalCal ? await bookPersonalInterview(payload) : await bookCandidateInterview(payload as IBookCandidateInterviewPubParams);
    if('error' in resp) {
      console.log('error', resp.error);
      if('data' in resp.error && resp.error.data === 'Retry with new slots.') {
        refetchSlots();
        setShowError(true);
      }
      return;
    }
    if('data' in resp) {
      onSubmitCB();
    }
  }, [email, name, isPersonalCal, accountId, 
    recaptcha, tokenParam, interview_candidate_id, 
    slotObj.slot, bookPersonalInterview, bookCandidateInterview, 
    refetchSlots, onSubmitCB
  ]);

  const goBackHandler = React.useCallback(() => {
    setShowError(false);
    goBack();
  }, [goBack]);

  React.useEffect(() => {
    if(token){
      setTokenParam(token);
    }
  }, [token]);

  return (<>
    <ScheduleWrapper>
      <ScheduleItemWrapper>
        <BackWrapper onClick={goBackHandler}><Icon icon="arrow_back" $size={18}/>Back to Availability</BackWrapper> 
        <LogoWrapper><img src={logoUrl || logo} alt="findem-logo"/></LogoWrapper>
        <TimeSlotWrapper>
          <DayTitle style={{textAlign: 'center'}}>{moment(slotObj.slot[0].start).format('dddd, MMMM Do, YYYY')}</DayTitle>
          <HiInfo>
            {slotObj.label || `${moment(slotObj.slot[0].start).format('h:mm A')} - ${moment(slotObj.slot.at(-1)?.end).format('h:mm A')}` }
          </HiInfo>
        </TimeSlotWrapper>
      </ScheduleItemWrapper>
      <ScheduleItemWrapper showBackground={true} style={{marginTop: 0}}>
        <TimeSlotWrapper>
          <DayTitle>{moment(slotObj.slot[0].start).format('dddd, MMMM Do, YYYY')}</DayTitle>
          {showError && <ItemLabel style={{color: 'red'}}>Please select new slots.</ItemLabel>}
        </TimeSlotWrapper>
        <ItemWrapper style={{marginTop: 24}}>
          <ItemLabel>Name</ItemLabel>
          <Form.Item
          name="name"
          rules={[{ required: true, message: 'Please input your name' }]}
          >
            <StyledInput value={name} onChange={(e) => setName(e.target.value)} />
          </Form.Item>
        </ItemWrapper>
        <ItemWrapper>
          <ItemLabel>Email</ItemLabel>
          <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your email' }]}
          >
            <StyledInput value={name} onChange={(e) => setEmail(e.target.value)} />
          </Form.Item>
        </ItemWrapper>
        <Button 
          loading={isSubmitting || isPersonalCalSubmitting} 
          onClick={onSubmit} disabled={!isValidEmail || !name} 
          style={{
            marginTop: 32, 
            width: '100%',
            color: (!isValidEmail || !name) ? themeC.colors['grey-500'] : themeC.colors['white'],
            backgroundColor: (!isValidEmail || !name) ? themeC.colors['white'] : themeC.colors['blue-400'],
            borderColor: (!isValidEmail || !name) ? themeC.colors['grey-200'] : themeC.colors['blue-400'],
          }}
        >Submit</Button>
      </ScheduleItemWrapper>
    </ScheduleWrapper>
  </>);
}

export default ConfirmationForm;