import React from "react";
import { HI, HiInfo, DetailsWrapper, InfoWrapper, LogoWrapper, NameWrapper, ButtonWrapper } from "./CandidateInterview.styled";
import { Button, Icon, Tag } from "maui";
import logo from "../../assets/svgs/findem_logo.svg";
import { IInterviewRound, IInterviewSpec, IInterviewStage } from "../../types/calendar";
import { DefaultTheme } from "styled-components";
import { useSelector } from "react-redux";

interface IInterviewInfoProps {
  spec?: IInterviewSpec;
  goToSchedule: () => void;
  candidateName?: string;
  logoUrl?: string | undefined;
  themeColor?: string;
}


const InterviewInfo: React.FC<IInterviewInfoProps> = ({ spec, goToSchedule, candidateName, logoUrl, themeColor }) => {
  
  const themeC: DefaultTheme = useSelector((state: any) => state.appState.theme);

  const firstStage: IInterviewStage | undefined = React.useMemo(() => spec ? spec.stages[0] : undefined, [spec]); 
  const stagesCount: number = React.useMemo(() => (firstStage ? [firstStage] : []).reduce((acc, stage) => acc + stage.interview_round.length, 0), [firstStage]);
  const totalDuration: number = React.useMemo(() => (firstStage ? [firstStage] : []).reduce((acc, stage) => acc + stage.interview_round.reduce((acc, round) => acc + (round.schedule?.duration_minutes || 30), 0), 0), [firstStage]);

  const allRounds: IInterviewRound[] = React.useMemo(() =>{
    const rounds: IInterviewRound[] = [];
    (firstStage ? [firstStage] : []).forEach((stage) => {
      rounds.push(...stage.interview_round);
    });
    return rounds;
  }, [firstStage]);
  return (<>
    <InfoWrapper>
      <LogoWrapper><img src={logoUrl || logo} alt="findem-logo"/></LogoWrapper>
      <NameWrapper>
        <HI>Hi{candidateName ? ` ${candidateName} ` : ''}👋 Excited to meet you!</HI>
        <HiInfo>Let’s find a good time to meet.</HiInfo>
      </NameWrapper>
      {firstStage && <DetailsWrapper>
        <div>
          <HI>Your Interview</HI>
          <HiInfo>{totalDuration} mins total time with {stagesCount} events</HiInfo>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
          {allRounds.map((round, index) => (
            <div key={index}>
              <Tag style={{color: themeC.colors['blue-400'], backgroundColor: themeC.colors['blue-200']}}>
                <Icon icon="schedule" style={{
                  position: 'relative',
                  top: '2px',
                  marginRight: '4px',
                }}/>
                {round.schedule?.duration_minutes || 30} mins
              </Tag>
              {round.name}
            </div>)
          )}
        </div>
      </DetailsWrapper>}
      <ButtonWrapper>
        <Button 
          style={{marginTop: 32, 
            color: themeC.colors['white'], 
            backgroundColor: themeC.colors['blue-400'],
            borderColor: themeC.colors['blue-400'],
          }} onClick={goToSchedule}>Get Started</Button>
      </ButtonWrapper>
    </InfoWrapper>
  </>);
};

export default InterviewInfo;